import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import Button from '../../components/button';
import CollapsibleBox from '../../components/collapsibleBox';
import Grid from '../../components/grid';
import Layout from '../../components/layout';
import LeadText from '../../components/leadText';
import Masthead from '../../components/masthead';
import Quote from '../../components/quote';
import Section from '../../components/section';
import SEO from '../../components/seo';

const ProjectsMPlan = ({ data, location }) => {
  const parentTitle = 'Projects';
  const pageTitle = 'The M-Plan';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:40vh;`]}
      />
      <Section>
        <h2>Overview</h2>
        <div css={tw`mb-2 ml-10 float-right`}>
          <div css={tw`mt-5 flex items-center justify-center`}>
            <Img
              fixed={data.ndp.childImageSharp.fixed}
              alt="NDP"
              css={tw`mr-2`}
            />
            <Img
              fixed={data.sdgs.childImageSharp.fixed}
              alt="SDGs"
              css={tw`mr-4`}
            />
            <Img fixed={data.agenda63.childImageSharp.fixed} alt="Agenda63" />
          </div>
        </div>
        <LeadText>
          The Social Justice M-Plan is a social justice accelerator programme,
          named after Palesa Musa and modeled against the post-World War II,
          Europe Recovery Plan. It is aimed at fostering applied integrated
          research and related activities in order to catalyse the process of
          ending poverty and equalising opportunities by 2030, in support of the
          National Development Plan (NDP), Agenda 2063 and the UN Sustainable
          Development Goals (SDGs). It also seeks to foster social
          accountability and social cohesion, mobilise corporate and civil
          society resources to fund socio-economic inclusion and foster civic
          responsibility.
        </LeadText>
        <p>
          The M-Plan aims to leverage data analytics to enhance the state’s
          capacity to pass laws that reduce poverty and inequality and to foster
          a culture of data based decision-making, focussing on assessing and
          predicting the likely social justice impact of planned policies,
          legislation and plans as well as those already in operation.
        </p>
        <p>
          The name is in honor of Palesa Musa, an anti-apartheid activist who at
          the tender age of 12 was one of the school children that were
          arrested, detained and tortured for challenging the apartheid
          government. Today, Musa serves as a reminder of how the shadow of the
          past influences the present.
        </p>
        <Quote withBox css={tw`mb-10`}>
          &ldquo;We don’t want democracy. We fought for freedom and instead got
          this thing called democracy. During apartheid the pass laws undermined
          our ability to live freely and to prosper, while today poverty
          achieves exactly the same purpose.&rdquo;
          <small css={tw`text-dark-gray-700`}>&mdash; Palesa Musa</small>
        </Quote>
        <p>
          Truth be told, Musa is one of millions of people, mostly young and
          poor, who feel that the government has failed them in that the
          constitutional promise of freed potential and improved quality of life
          has not yet reached them. This sentiment is echoed in Rekgotsofetse
          Chikane’s book{' '}
          <em>
            Breaking a Rainbow, Building a Nation: The Politics Behind #MustFall
            Movements
          </em>{' '}
          (2018, Johannesburg: Picador Africa), specifically in a chapter
          titled: “We Were Sold Dreams in ’94 – We Want a Refund”. Many such
          left-behind or discontented groups and communities are increasingly
          finding demagogues and related extremism attractive. They are
          increasingly looking to political entrepreneurs who are offering
          alternatives to democracy that will lift them out of poverty, hunger,
          unemployment, landlessness and related deprivations Truth be told,
          Musa is one of millions of people, mostly young and poor, who feel
          that the government has failed them in that the constitutional promise
          of freed potential and improved quality of life has not yet reached
          them. This sentiment is echoed in Rekgotsofetse Chikane’s book
        </p>
        <Button to="/downloads/m-plan/m-plan-structures.pdf" size="md">
          Download a booklet on the M-Plan and M-Fund &rarr;
        </Button>
      </Section>
      <Section isShaded>
        <CollapsibleBox collapsibleByDefault>
          <h2>Context</h2>
          <div>
            <LeadText>
              The M-Plan is aimed at fostering applied integrated research and
              related activities in order to catalyse the process of ending
              poverty and equalising opportunities by 2030, in support of the
              National Development Plan (NDP), Agenda 2063 and the UN
              Sustainable Development Goals (SDGs).
            </LeadText>
            <p>
              The M-Plan seeks to coordinate systematic and integrated academic,
              business and broader civil society input to support government
              efforts towards breaking the back of poverty and inequality. It is
              also an avenue for giving effect to the Stellenbosch University’s
              Social Impact objective, particularly with regard to the grand
              constitutional objective of healing the divisions of the past and
              transforming structural social relations in society to give effect
              to the constitutional promise of freeing the potential and
              improving the quality of life of every citizen.
            </p>
          </div>
        </CollapsibleBox>
        <CollapsibleBox collapsibleByDefault>
          <h2>Objectives</h2>
          <div>
            <LeadText>
              The Centre for Social Justice is anchored in the triple
              objectives of responsive research, teaching and social impact.
              Research includes auditing and integrating existing social justice
              research and data while generating new knowledge and information
              where necessary. Teaching on the other hand, extends to fostering
              appropriate knowledge, skills and values among decision-makers
              that drive public policy, planning and monitoring.
            </LeadText>
            <p>The Social Justice M-Plan key result areas aim to:</p>
            <ol>
              <li>
                Empower policy and law-makers to leverage data analytics to
                catalyse social justice and SDG 16 responsive law and policy
                reform;
              </li>
              <li>
                Foster social accountability and social cohesion through legal,
                human rights and democracy awareness and empowerment
                initiatives;
              </li>
              <li>
                Mobilise societal, corporate and international support and
                resources towards accelerated reduction of poverty and
                inequality by 2030; and
              </li>
              <li>
                Encourage leadership among all and contribute to a capable
                state.
              </li>
            </ol>
          </div>
        </CollapsibleBox>
        <CollapsibleBox collapsibleByDefault>
          <h2>The Theory of Change Underpinning the Social Justice M-Plan</h2>
          <div>
            <LeadText>
              The Social Justice M-Plan moves from the premise that despite
              numerous transformation policies and resource investments since
              the dawn of democracy, the poverty and inequality gap has been
              increasing due to the exponential impact of structural accumulated
              socio-economic advantages and disadvantages among historically
              privileged and oppressed groups respectively and misaligned policy
              responses.
            </LeadText>
            <p>
              The theory of change behind the Social Justice M-Plan is that
              poverty and inequality are systems problems and require a systems
              approach to change. Current approaches tend to adopt a silver
              bullet paradigm. Poverty and inequality operate like debt,
              increasing exponentially with minimum repayment efforts and
              decreasing exponentially with significant investment. Current
              approaches to poverty alleviation are bedeviled by a
              ‘one-size-fits-all’ paradigm for mainstream policies, while
              creating in ramps for the historically oppressed whose gains are
              easily offset by the fact that the ‘one-size-fits-all’ in the
              mainstream leaves those already disadvantaged behind. Different
              dimensions of poverty and inequality feed on and foster each
              other. Gender, spatial disparities, disability, class and other
              forms of inequality intersect with other forms of social
              injustice, such as sexual orientation and age, compounding
              disadvantage.
            </p>
            <p>
              The assumptions further take into account the deleterious impact
              of corruption and related governance failure on social justice. In
              response thereto, the Social Justice M- Plan seeks to sponsor
              reinforced public participation in public policy processes,
              service monitoring and exacting accountability to ensure that
              whatever is gained through the M-Plan is not offset by what’s lost
              through corruption and related governance and service failure in
              state affairs.
            </p>
          </div>
        </CollapsibleBox>
        <CollapsibleBox collapsibleByDefault>
          <h2>Fostering Collaboration</h2>
          <div>
            <LeadText>
              Key to the Social Justice M-Plan and its interdisciplinary nature
              is the appreciation of the importance of collaboration within the
              university, and between universities, disciplines, the state,
              society and business.{' '}
            </LeadText>
            <p>
              The coordination of resources will be critical for this purpose.
              The M-Plan is ultimately intended to be a collaborative venture
              between Stellenbosch University and other academic institutions in
              a manner that builds bridges between diverse communities.
            </p>
          </div>
        </CollapsibleBox>
        <CollapsibleBox collapsibleByDefault>
          <h2>Key Activities</h2>
          <div>
            <ol>
              <li>
                <strong>Social Justice resonant policies and laws.</strong>{' '}
                Activities include the establishment of a Social Justice hub;
                think-tank coordination; policy-briefs and social justice
                analytics tools design.
              </li>
              <li>
                <strong>Social accountability and social cohesion.</strong>{' '}
                Activities include an Everyday Justice legal empowerment
                initiative working with students to enhance access to justice
                for disadvantaged groups and communities. It also includes
                social justice cafes and #Dear President initiative and
                fostering accountability for constitutional, SDG and NDP
                imperatives for social justice.
              </li>
              <li>
                <strong>Resource mobilization.</strong> The flagship activity
                involves encouraging retail outlets to help collect small public
                donations of R2,00 or more towards an #Action4Inclusion Fund
                managed by a Council of Social Justice Champions. The fund is
                directed towards impact investment in education and ward-based
                socio-economic inclusion initiatives in 4392 wards.
              </li>
              <li>
                <strong>
                  Encourage leadership among all and contribute to a capable
                  state.
                </strong>
                Design a poverty and inequality map and encourage social justice
                championship that has targeted the poorest areas on it. This
                includes encouraging a strategic investment legal framework
                targeted at reducing poverty and inequality.
              </li>
            </ol>
          </div>
        </CollapsibleBox>
      </Section>
      <Section>
        <h2>Resources</h2>
        <Grid>
          <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
            <h3 css={tw`mb-2`}>
              <small>Brochure:</small>
              <br />
              Introducing the Social Justice M-Plan
            </h3>
            <Button
              css={tw`mt-auto`}
              to="/downloads/m-plan/m-plan-brochure.pdf"
            >
              Download &rarr;
            </Button>
          </div>
          <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
            <h3 css={tw`mb-2`}>
              <small>Summary Report:</small>
              <br />
              Expert Round Table
            </h3>
            <LeadText css={tw`mt-auto mb-2`}>October 2018</LeadText>
            <Button to="/downloads/m-plan/2018-final-expert-roundtable-report.pdf">
              Download &rarr;
            </Button>
          </div>
          <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
            <h3 css={tw`mb-2`}>
              <small>Summary Report:</small>
              <br />
              Towards a socially-just and sustainable economy
            </h3>
            <LeadText css={tw`mt-auto mb-2`}>March 2019</LeadText>
            <Button to="/downloads/m-plan/2019-final-mark-swilling-report.pdf">
              Download &rarr;
            </Button>
          </div>
          <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
            <h3 css={tw`mb-2`}>
              <small>Summary report:</small>
              <br />
              Inaugural Social Justice Summit and International Conference
            </h3>
            <LeadText css={tw`mt-auto mb-2`}>August 2019</LeadText>
            <Button to="/downloads/m-plan/2019-su-summit-conference-report.pdf">
              Download &rarr;
            </Button>
          </div>
          <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
            <h3 css={tw`mb-2`}>
              <small>Policy Brief:</small>
              <br />
              Land
            </h3>
            <LeadText css={tw`mt-auto mb-2`}>November 2019</LeadText>
            <Button to="/downloads/m-plan/2019-su-policy-brief.pdf">
              Download &rarr;
            </Button>
          </div>
          <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
            <h3 css={tw`mb-2`}>
              <small>Concept Paper:</small>
              <br />
              M-Plan
            </h3>
            <LeadText css={tw`mt-auto mb-2`}>June 2020</LeadText>
            <Button to="/downloads/m-plan/m-plan-original-tm-2-June-ed.pdf">
              Download &rarr;
            </Button>
          </div>
          <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
            <h3 css={tw`mb-2`}>
              <small>Booklet:</small>
              <br />
              The M-Plan structures, including the M-Fund
            </h3>
            <LeadText css={tw`mt-auto mb-2`}>October 2020</LeadText>
            <Button to="/downloads/m-plan/m-plan-structures.pdf">
              Download &rarr;
            </Button>
          </div>
        </Grid>
      </Section>
    </Layout>
  );
};

export default ProjectsMPlan;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ndp: file(relativePath: { eq: "projects-mplan-ndp.jpg" }) {
      childImageSharp {
        fixed(quality: 100, width: 150) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sdgs: file(relativePath: { eq: "projects-mplan-sdgs.jpg" }) {
      childImageSharp {
        fixed(quality: 100, width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    agenda63: file(relativePath: { eq: "projects-mplan-agenda63.jpg" }) {
      childImageSharp {
        fixed(quality: 100, width: 68) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
