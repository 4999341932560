import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import tw from 'tailwind.macro';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';
import LeadText from './leadText';

const fullConfig = resolveConfig(tailwindConfig);

const Masthead = ({ parentTitle, pageTitle, backgroundImage, styling }) => {
  return (
    <>
      <BackgroundImage
        fluid={[
          `linear-gradient(${fullConfig.theme.colors.maroon.translucent}, ${fullConfig.theme.colors.maroon.default})`,
          backgroundImage,
        ]}
        css={[tw`flex items-center py-10`, styling]}
      >
        <div className="container">
          <div>
            <LeadText
              css={[
                tw`my-0 uppercase tracking-wide text-white`,
                `text-shadow:0 0 10px rgba(0,0,0,0.5)`,
              ]}
              className="text-shadow"
            >
              {parentTitle}
            </LeadText>
            <h1
              className="text-shadow"
              css={[
                tw`my-0 text-white tracking-wider`,
                `text-shadow:0 0 10px rgba(0,0,0,0.5)`,
              ]}
            >
              {pageTitle}
            </h1>
          </div>
        </div>
      </BackgroundImage>
    </>
  );
};

export default Masthead;
