import { css } from "@emotion/core"
import styled from "@emotion/styled"
import React, { useState } from "react"
import tw from "tailwind.macro"

const Title = styled.div`
  h2,
  h3 {
    margin: 0.75rem 0 !important;
  }
`
const Content = tw.div``

const CollapsibleBox = ({ collapsibleByDefault, unstyled, children }) => {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <div
      css={[
        tw`mb-5 p-5 sm:px-10 sm:py-5 shadow-md bg-white rounded`,
        unstyled && tw`md:p-0 md:shadow-none rounded-none`,
      ]}
    >
      <Title
        css={tw`flex items-center`}
        onClick={() => toggleExpansion(!isExpanded)}
      >
        {children[0]}
        <span
          css={[
            tw`ml-auto pl-3 text-2xl text-stone`,
            !collapsibleByDefault && tw`md:hidden`,
          ]}
        >
          {isExpanded
            ? String.fromCharCode("8593")
            : String.fromCharCode("8595")}
        </span>
      </Title>
      <Content
        className={`transition-max-height duration-300 ${
          isExpanded ? `max-h-2x-screen` : `max-h-0`
        } ${!collapsibleByDefault && `md:max-h-full`}`}
        css={css`
          ${tw`overflow-hidden`}
        `}
      >
        {children[1]}
      </Content>
    </div>
  )
}

export default CollapsibleBox
