import BackgroundImage from "gatsby-background-image"
import PropTypes from "prop-types"
import React from "react"
import { FaQuoteRight } from "react-icons/fa"
import tw from "tailwind.macro"

const Quote = ({ withBox, withImage, children, ...other }) => {
  let backgrounds = []
  backgrounds.push(`linear-gradient(90deg, rgba(255,255,255,0), #FFF 35%)`)
  if (withImage) {
    backgrounds.push(withImage)
  }

  return (
    <BackgroundImage
      fluid={backgrounds}
      css={[
        withBox &&
          tw`p-5 md:p-10 bg-white bg-contain rounded shadow-lg overflow-hidden`,
        withImage && tw`bg-left`,
      ]}
      {...other}
    >
      <blockquote css={withImage && tw`w-3/4 ml-auto`}>
        <div css={tw`text-right`}>
          <FaQuoteRight css={tw`inline-block text-4xl text-maroon`} />
        </div>
        <p css={tw`text-xl italic`}>{children}</p>
        {/*<p css={tw`mb-0 text-right`}>
          <Button to="https://www.google.com" size="xs" variant="muted">
            <FaTwitter css={tw`inline`} /> Tweet this
          </Button>
    </p>*/}
      </blockquote>
    </BackgroundImage>
  )
}

Quote.defaultProps = {
  withBox: false,
  withImage: false,
}

Quote.propTypes = {
  withBox: PropTypes.bool,
}

export default Quote
